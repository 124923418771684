@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --viewport-height: 100vh;
}

.ql-hidden {
  display: none;
}

.ql-disabled > .ql-editor {
  padding-left: 0;
  padding-right: 0;
}

@font-face {
  font-family: 'Insaniburger';
  src: local('Insaniburger'), url('./fonts/Insanibu.ttf') format('truetype');
}

.slick-dots .ft-slick__dots--custom {
  background-color: lightGrey;
  width: 6px;
  height: 6px;
  border-radius: 1000px;
  transition: width 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 30px;
  background: linear-gradient(135deg, #ec407a, #ab47bc);
  transition: width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  width: 6px !important;
}

.slick-dots .slick-active {
  width: 30px !important;
}

@keyframes basketBump {
  0% {
    transform: scale(1) rotate(0deg);
  }
  10% {
    transform: scale(1.1) rotate(-20deg);
  }
  30% {
    transform: scale(0.9) rotate(20deg);
  }
  50% {
    transform: scale(1.05) rotate(-10deg);
  }
  70% {
    transform: scale(1) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.button-enter {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.button-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.scrollable-content {
  scroll-behavior: smooth;
}

/* Google Button */
.gsi-material-button {
  user-select: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #001d35;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #001d35;
  opacity: 8%;
}
/* End Google Button */
